// every dependency has to be imported here
// css, scss, image, fonts etc.
import bulma from "./assets/scss/bulma.scss"; // main bulma styles
import {
  Bulma
} from './classes/Bulma'; // bulma stuff. Navi etc.
import 'lazysizes'; // https://www.npmjs.com/package/lazysizes
import GLightbox from 'glightbox'; // https://www.npmjs.com/package/glightbox
import "../node_modules/glightbox/dist/css/glightbox.min.css";
import Splide from '@splidejs/splide'; // slider/carousel https://splidejs.com/
import "@splidejs/splide/dist/css/splide.min.css";
import "@mdi/font/css/materialdesignicons.min.css";
import youtubeDSGVO from './classes/youtubeDSGVO';

/* init methods */
new Bulma().initNavbar();
//new Bulma().initDropdowns();
const lightbox = GLightbox({});

const splideEl = document.querySelector('.splide');
if (splideEl) {

  var splide = new Splide( '.splide', {
    type   : 'loop',
    perPage: 3,
    breakpoints: {
      640: {
        perPage: 2,
      },
    }
  } );
  splide.mount();
}

if (document.querySelectorAll('.g360_ytembed') && document.querySelectorAll('.g360_ytembed').length > 0) {
  localStorage.setItem('g360_ytlisten', false); // listener not listening
  new youtubeDSGVO().init('');
}