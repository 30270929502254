/* 
YouTube Embed DSGVO Plugin
(c) 2020, Tobias Gerlach (tobias@gerlach360.de)
*/
export default class youtubeDSGVO {

  getVideotitle(videoID, i, videoSrc) {


    if (videoSrc === 'youtube') {

      /* https://stackoverflow.com/a/32190892/5997781 */

      var vidUrl = encodeURIComponent('https://www.youtube.com/watch?v=' + videoID);
      var url = 'https://noembed.com/embed?url=' + vidUrl + '&format=json';

      var xhr = new XMLHttpRequest();
      xhr.open('GET', url); // API
      xhr.onerror = function () {
        console.error("ERR");
      }
      xhr.onload = function () {
        var response = JSON.parse(this.response);
        //console.log(response);
        var title = response.title;
        document.getElementById('g360_vidtitle' + i).innerHTML = title; // render title
      }
      xhr.send();

    } else if (videoSrc === 'vimeo') {

      let url = `https://vimeo.com/api/v2/video/${videoID}.json`; // https://stackoverflow.com/questions/1361149/get-img-thumbnails-from-vimeo

      var xhr = new XMLHttpRequest();
      xhr.open('GET', url); // API
      xhr.onerror = function () {
        console.error("ERR");
      }
      xhr.onload = function () {
        var response = JSON.parse(this.response);
        var title = response[0].title;
        document.getElementById('g360_vidtitle' + i).innerHTML = title; // render title
      }
      xhr.send();

    }

  }

  placeholder(i, videoID, videoSrc) {

    let legalText = '';
    let playIcon = '';
    let thumbnailURL = '';

    if (videoSrc === 'youtube') {

      legalText = `Das Video wird von YouTube bereitgestellt.<br/>Es gelten die <a href="https://www.google.com/intl/de/policies/privacy/index.html" style="color:#FF0000;" target="_blank">Datenschutzbestimmungen von Google.</a><br/>Mit Klick auf den Play-Button, erlauben Sie die Verbindung zu YouTube.`;

      playIcon = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" class="g360_ytplayicon" x="0px" y="0px" viewBox="0 0 1024 721" enable-background="new 0 0 1024 721" xml:space="preserve"><path id="Triangle" fill="#FFFFFF" d="M407,493l276-143L407,206V493z"/><path id="The_Sharpness" opacity="0.12" fill="#420000" d="M407,206l242,161.6l34-17.6L407,206z"/><g id="Lozenge"><g><linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="512.5" y1="719.7" x2="512.5" y2="1.2" gradientTransform="matrix(1 0 0 -1 0 721)"><stop offset="0" style="stop-color:#E52D27"/><stop offset="1" style="stop-color:#BF171D"/></linearGradient><path fill="url(#SVGID_1_)" d="M1013,156.3c0,0-10-70.4-40.6-101.4C933.6,14.2,890,14,870.1,11.6C727.1,1.3,512.7,1.3,512.7,1.3    h-0.4c0,0-214.4,0-357.4,10.3C135,14,91.4,14.2,52.6,54.9C22,85.9,12,156.3,12,156.3S1.8,238.9,1.8,321.6v77.5    C1.8,481.8,12,564.4,12,564.4s10,70.4,40.6,101.4c38.9,40.7,89.9,39.4,112.6,43.7c81.7,7.8,347.3,10.3,347.3,10.3    s214.6-0.3,357.6-10.7c20-2.4,63.5-2.6,102.3-43.3c30.6-31,40.6-101.4,40.6-101.4s10.2-82.7,10.2-165.3v-77.5    C1023.2,238.9,1013,156.3,1013,156.3z M407,493V206l276,144L407,493z"/></g></g></svg>'

      thumbnailURL = 'https://img.youtube.com/vi/' + videoID + '/hqdefault.jpg';

    } else if (videoSrc === 'vimeo') {

      legalText = `Das Video wird von Vimeo bereitgestellt.<br/>Es gelten die <a href="https://vimeo.com/privacy" style="color:#49AFE1;" target="_blank">Datenschutzbestimmungen von Vimeo.</a><br/>Mit Klick auf den Play-Button, erlauben Sie die Verbindung zu Vimeo.`;

      playIcon = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" class="g360_ytplayicon" x="0px" y="0px" viewBox="0 0 1024 721" enable-background="new 0 0 1024 721" xml:space="preserve"><path id="Triangle" fill="#FFFFFF" d="M407,493l276-143L407,206V493z"/><path id="The_Sharpness" opacity="0.12" fill="#420000" d="M407,206l242,161.6l34-17.6L407,206z"/><g id="Lozenge"><g><linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="512.5" y1="719.7" x2="512.5" y2="1.2" gradientTransform="matrix(1 0 0 -1 0 721)"><stop offset="0" style="stop-color:#49AFE1"/><stop offset="1" style="stop-color:#49AFE1"/></linearGradient><path fill="url(#SVGID_1_)" d="M1013,156.3c0,0-10-70.4-40.6-101.4C933.6,14.2,890,14,870.1,11.6C727.1,1.3,512.7,1.3,512.7,1.3    h-0.4c0,0-214.4,0-357.4,10.3C135,14,91.4,14.2,52.6,54.9C22,85.9,12,156.3,12,156.3S1.8,238.9,1.8,321.6v77.5    C1.8,481.8,12,564.4,12,564.4s10,70.4,40.6,101.4c38.9,40.7,89.9,39.4,112.6,43.7c81.7,7.8,347.3,10.3,347.3,10.3    s214.6-0.3,357.6-10.7c20-2.4,63.5-2.6,102.3-43.3c30.6-31,40.6-101.4,40.6-101.4s10.2-82.7,10.2-165.3v-77.5    C1023.2,238.9,1013,156.3,1013,156.3z M407,493V206l276,144L407,493z"/></g></g></svg>'

      thumbnailURL = `https://vumbnail.com/${videoID}.jpg`;

    }

    return '<span class="g360_vidtitle" id="g360_vidtitle' + i + '"></span><div class="g360_dsgvoinfo" style="background-image:url(' + thumbnailURL + ');" ><div class="g360_dsgvoinfo-wrap"><span class="g360_showvid ' + videoSrc + ' " data-id="' + i + '" id="g360_showvid' + i + '">' + playIcon + '</span><span class="dpnote">' + legalText + '</span></div></div>'
  }


  sizeingPlaceholder() {

    var embedVideos = document.querySelectorAll('.not-allowed'); // getting all elements not-allowed
    for (var i = 0; i < embedVideos.length; i++) {
      var w = embedVideos[i].offsetWidth;
      var h = w / 1.777; // 16/9
      embedVideos[i].style.height = h + "px";
    }
  }

  init(idOfItem) {

    // idOfItem = in case, this is the id of the item the user has allowed youtube
    // in this case, we wanna start the video asap

    var embedCode = '';

    var yt_allowed = localStorage.getItem('yt_allowed'); // has user yt already allowed? true/false
    var vi_allowed = localStorage.getItem('vi_allowed'); // has user vi already allowed? true/false
    var alreadyListen = localStorage.getItem('g360_ytlisten'); // is resize listener already listen

    var embedVideos = document.querySelectorAll('.g360_ytembed'); // getting all elements

    for (var i = 0; i < embedVideos.length; i++) {

      var videoID = embedVideos[i].getAttribute("data-videoid");
      var videoSrc = embedVideos[i].getAttribute("data-src"); // youtube, vimeo ...

      // set id of each item
      embedVideos[i].setAttribute("id", "g360video_" + i);


      if (videoSrc === 'youtube') {

        if (yt_allowed === 'true') {

          // is allowed

          // remove class, if there
          if (embedVideos[i].classList.contains('not-allowed')) {
            embedVideos[i].classList.remove("not-allowed");
          }

          var autoplay = 0;

          if (parseInt(idOfItem) === i) {
            autoplay = 1;
          }

          embedCode = '<div class="g360_videocontainer"><iframe src="https://www.youtube-nocookie.com/embed/' + videoID + '?rel=0&autoplay=' + autoplay + '" style="width:100%;height:100%;border:0;" allow="autoplay; encrypted-media"></iframe></div>';
          embedCode += '<div class="g360_ytemb_legalinfo">&#9432; Diese Webseite stellt eine Verbindung zu YouTube her, da Sie dieses erlaubt haben. <span class="g360_yt_disallow">Erlaubnis entziehen</a></div>';
          embedVideos[i].innerHTML = embedCode;

          embedVideos[i].style.height = "auto"; // set height

          // g360_ytemb_disallow

          var disallowButtons = document.querySelectorAll('.g360_yt_disallow'); // getting all elements
          for (var j = 0; j < disallowButtons.length; j++) {
            disallowButtons[i].addEventListener('click', function (e) {
              e.preventDefault();
              localStorage.setItem('yt_allowed', false);
              new youtubeDSGVO().init('');
            })
          }

        } else {

          // not allowed (yet)
          embedVideos[i].innerHTML = this.placeholder(i, videoID, videoSrc);
          this.getVideotitle(videoID, i, videoSrc);

          embedVideos[i].className += " not-allowed"; // adding a class

          // Sizeing + Listener
          this.sizeingPlaceholder(); // sizeing placeholder

          if (alreadyListen !== 'true') {

            window.addEventListener("orientationchange", function () {
              this.sizeingPlaceholder(embedVideos[i]); // sizeing placeholder
            }.bind(this));

            window.addEventListener("resize", function () {
              this.sizeingPlaceholder(embedVideos[i]); // sizeing placeholder
            }.bind(this));

            localStorage.setItem('g360_ytlisten', true); // listener now listening

          }

          var allowButtons = document.querySelectorAll('.g360_showvid'); // getting all elements
          for (var j = 0; j < allowButtons.length; j++) {

            allowButtons[i].addEventListener('click', function (e) {

              var idOfItem = this.getAttribute("data-id");

              e.preventDefault();
              localStorage.setItem('yt_allowed', true);
              new youtubeDSGVO().init(idOfItem);

            })
          }
        }

        // /youtube

      } else if (videoSrc === 'vimeo') {

        if (vi_allowed === 'true') {

          // is allowed

          // remove class, if there
          if (embedVideos[i].classList.contains('not-allowed')) {
            embedVideos[i].classList.remove("not-allowed");
          }

          var autoplay = 0;

          if (parseInt(idOfItem) === i) {
            autoplay = 1;
          }


          embedCode = '<div class="g360_videocontainer"><iframe src="https://player.vimeo.com/video/' + videoID + '?title=0&byline=0&portrait=0&autoplay=1" style="width:100%;height:100%;border:0;" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div>';
          embedCode += '<div class="g360_ytemb_legalinfo">&#9432; Diese Webseite stellt eine Verbindung zu Vimeo her, da Sie dieses erlaubt haben. <span class="g360_vi_disallow">Erlaubnis entziehen</a></div>';
          embedVideos[i].innerHTML = embedCode;

          embedVideos[i].style.height = "auto"; // set height

          // g360_ytemb_disallow

          var disallowButtons = document.querySelectorAll('.g360_vi_disallow'); // getting all elements
          for (var j = 0; j < disallowButtons.length; j++) {
            disallowButtons[i].addEventListener('click', function (e) {
              e.preventDefault();
              localStorage.setItem('vi_allowed', false);
              new youtubeDSGVO().init('');
            })
          }

        } else {

          // not allowed (yet)
          embedVideos[i].innerHTML = this.placeholder(i, videoID, videoSrc);
          this.getVideotitle(videoID, i, videoSrc);

          embedVideos[i].className += " not-allowed"; // adding a class

          // Sizeing + Listener
          this.sizeingPlaceholder(); // sizeing placeholder

          if (alreadyListen !== 'true') {

            window.addEventListener("orientationchange", function () {
              this.sizeingPlaceholder(embedVideos[i]); // sizeing placeholder
            }.bind(this));

            window.addEventListener("resize", function () {
              this.sizeingPlaceholder(embedVideos[i]); // sizeing placeholder
            }.bind(this));

            localStorage.setItem('g360_vilisten', true); // listener now listening

          }

          var allowButtons = document.querySelectorAll('.g360_showvid'); // getting all elements
          for (var j = 0; j < allowButtons.length; j++) {

            allowButtons[i].addEventListener('click', function (e) {

              var idOfItem = this.getAttribute("data-id");

              e.preventDefault();
              localStorage.setItem('vi_allowed', true);
              new youtubeDSGVO().init(idOfItem);

            })
          }
        }

      } // /vimeo






    }

  }

}