/* all the bulma specific stuff goes in here */

export class Bulma {

  initNavbar() {

    // Get all "navbar-burger" elements
    const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {

      // Add a click event on each of them
      $navbarBurgers.forEach( el => {
        el.addEventListener('click', () => {

          // Get the target from the "data-target" attribute
          const target = el.dataset.target;
          const $target = document.getElementById(target);

          // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
          el.classList.toggle('is-active');
          $target.classList.toggle('is-active');

        });
      });
    }

  }


  initDropdowns() { // https://stackoverflow.com/a/58405701

    // Get all dropdowns on the page that aren't hoverable.
    const dropdowns = document.querySelectorAll('.dropdown:not(.is-hoverable)');

    if (dropdowns.length > 0) {
      // For each dropdown, add event handler to open on click.
      dropdowns.forEach(function(el) {
        el.addEventListener('click', function(e) {
          e.stopPropagation();
          el.classList.toggle('is-active');
        });
      });

      // If user clicks outside dropdown, close it.
      document.addEventListener('click', function(e) {
        closeDropdowns();
      });
    }

    /*
    * Close dropdowns by removing `is-active` class.
    */
    function closeDropdowns() {
      dropdowns.forEach(function(el) {
        el.classList.remove('is-active');
      });
    }

    // Close dropdowns if ESC pressed
    document.addEventListener('keydown', function (event) {
      let e = event || window.event;
      if (e.key === 'Esc' || e.key === 'Escape') {
        closeDropdowns();
      }
    });

  }

}